(function($) { "use strict";

	jQuery( document ).ready(function( $ ) {
	    $(function(){
		   $('#tabs li a').click(function(e){
			  $('#tabs li, #content .current').removeClass('current').removeClass('fadeInLeft');
			  $(this).parent().addClass('current');
			  var currentTab = $(this).attr('href');
			  $(currentTab).addClass('current fadeInLeft');
			  e.preventDefault();
		   });
	    });
	    
	    /*BxSlider*/
	    
	    $(document).ready(function(){
		  $('.bxslider').bxSlider();
		  mode: 'fade'
		});
		
		$(document).ready(function(){
		  $('.instagramslider').bxSlider();
		  mode: 'fade'
		});
		
		$(document).ready(function(){
		  $('.sidebarslider').bxSlider({
		  captions: 'true',
		  mode: 'fade'
		  });
		  
		});
	    
	    
	    /*Fancybox*/
	    
	    $(document).ready(function() {

			/* This is basic - uses default settings */
			
			$("a.slider_image").fancybox({
				'cyclic': true
			});
			
			$("a.gallery_slide").fancybox({
				'cyclic': true
			});
			
			$("a.flickr_img").fancybox({
				'cyclic': true
			});
			
			$("a#single_image").fancybox();
			
			
		});
	    
	});
	
	/*accordion with links in it*/
	$.fn.accordion_cf_wl = function() {
		$('.accordion-wrap-wl .accordion-head-wl').click(function(e){
			$(this).closest('li').find('.accordion-content-wl').not(':animated').slideToggle();
			
		});
		$(".accordion-wrap-wl li").click(function(){
		    $(this).toggleClass("opened");
		});
	}; 
	$('.accordion-wl').accordion_cf_wl();

})(jQuery);
